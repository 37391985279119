import { Box, Button } from "@mui/material";
import { ApiCaller } from "utils";
import axios from "axios";

const BackUp = () => {
  const handleBackup = async () => {
    try {
      const response = await axios.get("http://localhost:3001/backup", {
        responseType: "blob", // important to set response type to blob
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/json" });
      const link = document.createElement("a");

      // Create a link to download the file
      link.href = window.URL.createObjectURL(blob);
      link.download = `institute-backup.json`;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the backup:", error);
    }
  };
  return (
    <Box>
      <Button onClick={handleBackup}>BackUp</Button>
    </Box>
  );
};

export default BackUp;
