import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import Student from "./pages/Student";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Page404 from "./pages/Page404";
import Staff from "./pages/Staff";
import DashboardAppPage from "./pages/DashboardAppPage";
import AddInstitute from "pages/AddInstitute";
import Batch from "pages/Batch";
import AddBatch from "pages/AddBatch";
import AddStaff from "pages/AddStaff";
import AddStudent from "pages/AddStudent";
import ResetPassword from "pages/ResetPassword";
import VerifyOtp from "pages/VerifyOtp";
import ForgotPassword from "pages/ForgotPassword";
import { ProtectedRoute } from "components";
import LinkInstitute from "pages/LinkInstitute";
import Fees from "pages/Fees";
import AddFees from "pages/AddFees";
import FeeReceipt from "pages/AddFees/FeeReceipt";
import SalaryReceipt from "pages/AddSalary/SalaryReceipt";
import Salary from "pages/Salary";
import AddSalary from "pages/AddSalary";
import Attendance from "pages/Attendance";
import FaceCapture from "pages/Attendance/FaceCapture";
import Expenses from "pages/Expenses";
import AddExpense from "pages/AddExpense";
import StudentDetails from "pages/StudentDetails";
import StaffDetail from "pages/StaffDetail";
import SchoolKit from "pages/SchoolKit";
import SellSchoolKit from "pages/SellSchoolKit";
import AddSellSchoolKit from "pages/AddSellSchoolKit";
import SaleReceipt from "pages/AddSellSchoolKit/SaleReceipt";
import EditProfile from "pages/EditProfile";
import Setting from "pages/Setting";
import BackUp from "pages/BackUp";
import BulkUpload from "pages/BulkUpload";


export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: "app",
          element: (
            <ProtectedRoute>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "addInstitute",
          element: (
            <ProtectedRoute>
              <AddInstitute />
            </ProtectedRoute>
          ),
        },
        {
          path: "addBatch",
          element: (
            <ProtectedRoute>
              <AddBatch />
            </ProtectedRoute>
          ),
        },
        {
          path: "addStudent",
          element: (
            <ProtectedRoute>
              <AddStudent />
            </ProtectedRoute>
          ),
        },
        {
          path: "batch",
          element: (
            <ProtectedRoute>
              <Batch />
            </ProtectedRoute>
          ),
        },
        {
          path: "linkInstitute",
          element: (
            <ProtectedRoute>
              <LinkInstitute />
            </ProtectedRoute>
          ),
        },
        {
          path: "student",
          element: (
            <ProtectedRoute>
              <Student />
            </ProtectedRoute>
          ),
        },
        {
          path: "student-details",
          element: (
            <ProtectedRoute>
              <StudentDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: "staff-details",
          element: (
            <ProtectedRoute>
              <StaffDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "school-kit",
          element: (
            <ProtectedRoute>
              <SchoolKit />
            </ProtectedRoute>
          ),
        },
        {
          path: "staff",
          element: (
            <ProtectedRoute>
              <Staff />
            </ProtectedRoute>
          ),
        },
        {
          path: "addStaff",
          element: (
            <ProtectedRoute>
              <AddStaff />
            </ProtectedRoute>
          ),
        },
        {
          path: "fees",
          element: (
            <ProtectedRoute>
              <Fees />
            </ProtectedRoute>
          ),
        },
        {
          path: "salary",
          element: (
            <ProtectedRoute>
              <Salary />
            </ProtectedRoute>
          ),
        },
        {
          path: "addSalary",
          element: (
            <ProtectedRoute>
              <AddSalary />
            </ProtectedRoute>
          ),
        },
        {
          path: "addFees",
          element: (
            <ProtectedRoute>
              <AddFees />
            </ProtectedRoute>
          ),
        },
        {
          path: "feeReceipt",
          element: (
            <ProtectedRoute>
              <FeeReceipt />
            </ProtectedRoute>
          ),
        },
        {
          path: "sale-receipt",
          element: (
            <ProtectedRoute>
              <SaleReceipt />
            </ProtectedRoute>
          ),
        },
        {
          path: "salaryReceipt",
          element: (
            <ProtectedRoute>
              <SalaryReceipt />
            </ProtectedRoute>
          ),
        },
        {
          path: "expenses",
          element: (
            <ProtectedRoute>
              <Expenses />
            </ProtectedRoute>
          ),
        },
        {
          path: "sell-school-kit",
          element: (
            <ProtectedRoute>
              <SellSchoolKit />
            </ProtectedRoute>
          ),
        },
        {
          path: "add-sell-school-kit",
          element: (
            <ProtectedRoute>
              <AddSellSchoolKit />
            </ProtectedRoute>
          ),
        },
        {
          path: "add-expense",
          element: (
            <ProtectedRoute>
              <AddExpense />
            </ProtectedRoute>
          ),
        },
        {
          path: "attendance",
          element: (
            <ProtectedRoute>
              <Attendance />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit-profile",
          element: (
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "setting",
          element: (
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          ),
        },
        {
          path: "bulk-upload",
          element: (
            <ProtectedRoute>
              <BulkUpload />
            </ProtectedRoute>
          ),
        },
        {
          path: "backup",
          element: (
            <ProtectedRoute>
              <BackUp />
            </ProtectedRoute>
          ),
        },
        {
          path: "faceCapture",
          element: (
            <ProtectedRoute>
              <FaceCapture />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "resetPassword",
      element: <ResetPassword />,
    },
    {
      path: "verifyOtp",
      element: <VerifyOtp />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
