import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Box, IconButton, Typography } from "@mui/material";
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppConversionRates,
  PieChart,
} from "sections/@dashboard/app";
import { useCurrentUser, useMasterData } from "hooks";
import { useTranslation } from "react-i18next";
import { DatePicker, CustomLoader } from "components";
import { Controller, useForm } from "react-hook-form";
import {
  ApiCaller,
  formatDate,
  getBgColor,
  getCurrentMonthFirstAndLastDay,
} from "utils";

import { useNavigate } from "react-router-dom";
import useResponsive from "hooks/useResponsive";

const ICON_BG_COLOR = "#E3EDF6";

const NavigatorView = ({ icon, url, title, style, color = ICON_BG_COLOR }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      <Box
        onClick={() => navigate(`/dashboard/${url}`)}
        sx={{
          borderRadius: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 0.5,
          cursor: "pointer",
          backgroundColor: "#fff",
          ...style,
        }}
      >
        <IconButton
          disableRipple={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 65,
            height: 65,
          }}
        >
          <img src={`/assets/icons/${icon}.png`} alt={title} />
        </IconButton>
        <Typography
          variant="caption"
          sx={{ marginTop: -0.5 }}
          color={theme.palette.grey["500"]}
        >
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

export default function DashboardAppPage() {
  const [financialSummary, setFinancialSummary] = useState({});
  const [studentStaffSummary, setStudentStaffSummary] = useState({});
  const [instituteSummary, setInstituteSummary] = useState([]);
  const [batchStudents, setBatchStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const triggerMasterData = useMasterData();
  const triggerCurrentuser = useCurrentUser();
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();


  const { control, getValues, watch } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    setTimeout(() => {
      triggerMasterData();
      triggerCurrentuser();
      getStudentStaffSummary();
      getInstituteSummary();
      getBatchStudents();
    }, 500);
  }, []);

  useEffect(() => {
    getDashboardSummary();
  }, [watch("fromDate"), watch("toDate")]);

  const getDashboardSummary = () => {
    const fromDate = getValues("fromDate").toISOString();
    const toDate = getValues("toDate").toISOString();
    try {
      ApiCaller(`/financial-summary?from=${fromDate}&to=${toDate}`, "get").then(
        (response) => {
          if (response?.success) {
            setFinancialSummary(response?.data);
            setLoading(false);
          }
        }
      );
    } catch (error) {
      setLoading(false);
      console.log("error");
    }
  };

  const getStudentStaffSummary = () => {
    try {
      ApiCaller(`/student-staff-summary`, "get").then((response) => {
        if (response?.success) {
          setStudentStaffSummary(response?.data);
        }
      });
    } catch (error) {
      console.log("error");
    }
  };

  const getInstituteSummary = () => {
    try {
      ApiCaller(`/institute-summary`, "get").then((response) => {
        if (response?.success) {
          setInstituteSummary(response?.data);
        }
      });
    } catch (error) {
      console.log("error");
    }
  };

  const getBatchStudents = () => {
    try {
      ApiCaller(`/batch-wise-active-student`, "get").then((response) => {
        if (response?.success) {
          setBatchStudents(response?.data);
        }
      });
    } catch (error) {
      console.log("error");
    }
  };

  const dates = instituteSummary?.map((item) => item?.month);
  const monthlySale = instituteSummary?.map((item) => item?.totalSales);
  const monthlyFeesRecevied = instituteSummary?.map(
    (item) => item?.totalFeesReceived
  );
  const monthlySalaryPaid = instituteSummary?.map(
    (item) => item?.totalSalaryPaid
  );
  const monthlyExpenses = instituteSummary?.map((item) => item?.totalExpenses);

  const dateDuration = `${t("from")} ${formatDate(
    watch("fromDate")
  )} - ${t('to')} ${formatDate(watch("toDate"))}`;

  const formattedBatchStudent = batchStudents?.map((batchStudent) => {
    return {
      label: batchStudent.batchName,
      value: batchStudent.count,
    };
  });

  const activeStaffs = studentStaffSummary?.genderWiseActiveStaff;

  const activeStudents = studentStaffSummary?.genderWiseActiveStudents;

  return (
    <>
      <Helmet>
        <title> GurukulGuru </title>
      </Helmet>

      <CustomLoader loading={loading} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                backgroundImage: getBgColor(theme.palette.primary.main),
              }}
            >
              <Grid container>
                <NavigatorView
                  icon="add_student"
                  title={t("newAdmission")}
                  url="addStudent"
                  style={{ marginRight: 1, marginBottom: 1 }}
                  color={ICON_BG_COLOR}
                />

                <NavigatorView
                  icon="fees"
                  title={t("submitFee")}
                  url="addFees"
                  color={ICON_BG_COLOR}
                  style={{ marginLeft: 1, marginBottom: 1 }}
                />
                <NavigatorView
                  icon="staff"
                  title={t("newStaff")}
                  url="addStaff"
                  style={{ marginRight: 1, marginTop: 1 }}
                  color={ICON_BG_COLOR}
                />
                <NavigatorView
                  icon="salary"
                  title={t("paySalary")}
                  url="addSalary"
                  color={ICON_BG_COLOR}
                  style={{ marginLeft: 1, marginTop: 1 }}
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                backgroundColor: "#fff",
                padding: 2,
                borderRadius: 2,
                backgroundImage: getBgColor(theme.palette.primary.main),
              }}
            >
              <Grid container>
                <NavigatorView
                  icon="batch"
                  title={t("newBatch")}
                  url="addBatch"
                  style={{ marginRight: 1, marginBottom: 1 }}
                />
                <NavigatorView
                  icon="expense"
                  title={t("newExpenses")}
                  url="add-expense"
                  style={{ marginLeft: 1, marginBottom: 1 }}
                />
                <NavigatorView
                  icon="school_kit"
                  title={t("newInstituteKit")}
                  url="school-kit"
                  style={{ marginRight: 1, marginTop: 1 }}
                />
                <NavigatorView
                  icon="sell"
                  title={t("kitSales")}
                  url="add-sell-school-kit"
                  style={{ marginLeft: 1, marginTop: 1 }}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PieChart
              title={`${t("totalStudents")}: ${
                studentStaffSummary?.activeStudents ?? "-"
              }`}
              chartData={[
                {
                  label: t(`male`),
                  value: activeStudents?.Male || 0,
                },
                {
                  label: t(`female`),
                  value: activeStudents?.Female || 0,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PieChart
              title={`${t("totalStaffs")}: ${
                studentStaffSummary?.activeStaff ?? "-"
              }`}
              chartData={[
                {
                  label: t(`male`),
                  value: activeStaffs?.Male || 0,
                },
                {
                  label: t(`female`),
                  value: activeStaffs?.Female || 0,
                },
              ]}
              chartColors={[theme.palette.info.main, theme.palette.error.main]}
            />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item md={3}></Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3} px={mdUp ? 1.4 : 0} width={"100%"}>
            <Box backgroundColor="#fff" py={2} px={2} borderRadius={1}>
              <Controller
                control={control}
                name="fromDate"
                defaultValue={
                  new Date(getCurrentMonthFirstAndLastDay()?.firstDayOfMonth)
                }
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    size={"small"}
                    label={t("fromDate")}
                    value={value}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    maxDate={new Date()} // Disable future dates
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={3} px={mdUp ? 1 : 0} width={"100%"}>
            <Box
              backgroundColor="#fff"
              py={2}
              px={2}
              borderRadius={1}
              mt={!mdUp && 1}
              ml={mdUp && 1.2}
            >
              <Controller
                control={control}
                name="toDate"
                defaultValue={new Date()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t("toDate")}
                    value={value}
                    onChange={onChange}
                    size={"small"}
                    maxDate={new Date()} // Disable future dates
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>{" "}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t("feeCollection")}
              total={financialSummary.totalFeesReceived}
              title1={t(`pendingFees`)}
              value1={financialSummary.totalFeesRemaining}
              title2={t(`discountFees`)}
              value2={financialSummary.totalFeesDiscount}
              icon="fees"
              color={theme.palette.primary.main}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t("paidSalary")}
              total={financialSummary?.totalSalaryPaid}
              title1={t(`salaryDue`)}
              value1={financialSummary.totalRemainingSalary}
              color="#66cccc"
              icon={"salary"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t("studentKitSales")}
              total={financialSummary?.totalSales}
              color="#ff9f00"
              icon={"sell"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t("expenses")}
              total={financialSummary?.totalExpenses}
              color="#F45159"
              icon={"expense"}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title={t(`feeDetails`)}
              subheader={dateDuration}
              chartData={[
                {
                  label: t("feeCollection"),
                  value: financialSummary.totalFeesReceived || 0,
                },
                {
                  label: t("pendingFees"),
                  value: financialSummary.totalFeesRemaining || 0,
                },
                {
                  label: t("discountFees"),
                  value: financialSummary.totalFeesDiscount || 0,
                },
              ]}
              chartColors={[
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title={t("instituteMonthlySummary")}
              subheader=""
              chartLabels={dates}
              chartData={[
                {
                  name: t("feeCollection"),
                  type: "line",
                  fill: "solid",
                  data: monthlyFeesRecevied,
                },
                {
                  name: t("paidSalary"),
                  type: "line",
                  fill: "solid",
                  data: monthlySalaryPaid,
                },
                {
                  name: t("sales"),
                  type: "line",
                  fill: "gradient",
                  data: monthlySale,
                },
                {
                  name: t("expenses"),
                  type: "line",
                  fill: "solid",
                  data: monthlyExpenses,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title={t(`salaryDetails`)}
              subheader={dateDuration}
              chartData={[
                {
                  label: t("paidSalary"),
                  value: financialSummary.totalSalaryPaid || 0,
                },
                {
                  label: t("salaryDue"),
                  value: financialSummary.totalRemainingSalary || 0,
                },
              ]}
              chartColors={[
                theme.palette.primary.light,
                // theme.palette.warning.main,
                theme.palette.warning.light,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title={t("batchSummary")}
              subheader={t("studentsOfEachBatch")}
              chartData={formattedBatchStudent}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
