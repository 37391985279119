import React from "react";
import { Typography, } from "@mui/material";
import { CustomeModal, GridSkeleton } from "components";
import useResponsive from "hooks/useResponsive";
import { LoadingButton } from "@mui/lab";
import { formatDate, } from "utils";
import { useTranslation } from "react-i18next";

const StaffConfirmation = ({
  open,
  onClose,
  loading,
  onSubmit,
  staffsDetails,
}) => {
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();
  const  {name, email, qualification,mobile,salaryAmount, role, joiningDate} =
  staffsDetails;

  return (
    <CustomeModal
      open={open}
      onClose={onClose}
      style={{ width: mdUp ? "40%" : "80%" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
        {t("staffConfirmation")}
      </Typography>
      <Typography sx={{ fontSize: 16, marginBottom: 1 }}>
       {t("doYouWantToAddStaff")}
      </Typography>
      <GridSkeleton label={t("name")} value={name} />
      <GridSkeleton label={t("salaryAmount")} value={salaryAmount} />
       <GridSkeleton label={t("mobile")} value={mobile} />
      <GridSkeleton label={t("email")} value={email} />
       <GridSkeleton label={t("joiningDate")} value={formatDate(joiningDate)} />
       <GridSkeleton label={t("role")} value={role} />
       <GridSkeleton label={t("qualification")} value={qualification} />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
        onClick={onSubmit}
      >
        {t("submit")}
      </LoadingButton>
    </CustomeModal>
  );
};

export default StaffConfirmation;
