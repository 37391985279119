import { useTranslation } from "react-i18next";
import React from "react";
import { Box } from "@mui/material";

const Icon = ({ name }) => {
  return (
    <Box p={0.5} bgcolor={"#fff"} borderRadius={4}>
      <img
        src={`/assets/icons/${name}.png`}
        alt={name}
        height={20}
        width={20}
      />
    </Box>
  );
};

const useConfig = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("dashboard"),
      path: "/dashboard/app",
      icon: <Icon name="home" />,
    },
    {
      title: t("administration"),
      icon: <Icon name="admin" />,
      children: [
        {
          title: t("institute"),
          path: "/dashboard/addInstitute",
          icon: <Icon name="institute" />,
        },
        {
          title: t("staff"),
          path: "/dashboard/staff",
          icon: <Icon name="staff" />,
        },
        {
          title: t("batch"),
          path: "/dashboard/batch",
          icon: <Icon name="batch" />,
        },
        {
          title: t("student"),
          path: "/dashboard/student",
          icon: <Icon name="student" />,
        },
        {
          title: t("fees"),
          path: "/dashboard/fees",
          icon: <Icon name="fee" />,
        },
        {
          title: t("salary"),
          path: "/dashboard/salary",
          icon: <Icon name="salary" />,
        },
      ],
    },
    {
      title: t("instituteStore"),
      icon: <Icon name="instituteStore" />,
      children: [
        {
          title: t("expenses"),
          path: "/dashboard/expenses",
          icon: <Icon name="expense1" />,
        },
        {
          title: t("instituteKit"),
          path: "/dashboard/school-kit",
          icon: <Icon name="school_kit" />,
        },
        {
          title: t("sellInstituteKit"),
          path: "/dashboard/sell-school-kit",
          icon: <Icon name="sale" />,
        },
      ],
    },
    {
      title: t("bulkUpload"),
      path: "/dashboard/bulk-upload",
      icon: <Icon name="upload" />,
    },
    {
      title: t("backup"),
      path: "/dashboard/backup",
      icon: <Icon name="backup" />,
    },
    {
      title: t("logout"),
      path: "/login",
      icon: <Icon name="logout" />,
    },
  ];
};

export default useConfig;
