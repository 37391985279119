import { Box } from '@mui/material';

const BulkUpload = () => {
    return (
        <Box>
            Bulk Upload
        </Box>
    );
};

export default BulkUpload;