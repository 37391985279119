import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Loader } from "components";

export default function ConfirmationBox({
  title = "Confirmation message",
  message = "Are you sure, you want to delete?",
  yesButtonText = "Yes",
  noButtonText = "No",
  handleClose,
  handleConfirm,
  open,
  loading,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {noButtonText}
        </Button>
        {loading ? (
          <Loader
            loading={loading}
            sx={{ marginRight: 3.2, marginLeft: 3.2 }}
          />
        ) : (
          <Button onClick={handleConfirm} autoFocus>
            {yesButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
