import fetch from "cross-fetch";

const LOCAL = "http://localhost:3001";
const QA_URL = "https://sm-backend-ybq3.onrender.com";
const URL =
  window.location?.origin === "http://localhost:3000" ? LOCAL : QA_URL;

const ApiCaller = (endpoint, method = "get", body) => {
  const store = JSON.parse(localStorage.getItem("persist:root"));
  const loginData = JSON.parse(store?.loginData);

  const token = loginData?.data?.token;

  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json", "x-access-token": token },
    body: JSON.stringify(body),
  };

  return fetch(`${URL}${endpoint}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return {
        status: 100,
        message: "Ooops network error! Check your internet connection",
      };
    });
};

export default ApiCaller;
