import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useTheme,
} from "@mui/material";
import {
  ListHead,
  ListToolbar,
  LoaderWrapper,
  Ribbon,
  TablePaginationActions,
} from "components";
import Iconify from "components/Iconify";
import { ApiCaller, handleCheckBox, validateData } from "utils";
import { useSelector } from "react-redux";
import { fDate } from "utils/formatTime";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "components/ConfirmationBox";
import { useDelay } from "hooks";



export default function Batch() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [batch, setBatch] = useState([]);
  const currentUser = useSelector((state) => state?.currentUser);
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: "name", label: t("name"), alignRight: false },
    { id: "batchNo", label: t("batchNumber"), alignRight: false },
    { id: "batchTeacher", label: t("batchTeacher"), alignRight: false },
    { id: "startDate", label: t("startDate"), alignRight: false },
    { id: "endDate", label: t("endDate"), alignRight: false },
    { id: "feeType", label: t("feeType"), alignRight: false },
    { id: "defaultFee", label: t("defaultFee"), alignRight: false },
    { id: "", label: t("action"), alignRight: false },
  ];

  const navigation = useNavigate();
  const theme = useTheme();
  const batchList = batch?.data || [];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = batchList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const isNotFound = !batchList.length;

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(() => {
    setLoading(true);
    ApiCaller(
      `/batches?search=${searchedValue}&pageNo=${page + 1}&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setBatch(response);
        setLoading(false);
      }
    });
  }, [deletedToken, page, rowsPerPage, searchedValue]);

  const handleAddBatch = () => {
    navigation("/dashboard/addBatch");
  };

  const handleDelete = () => {
    const selectedBatch = batchList.filter(
      (batch) => selected.includes(batch.name) && batch._id
    );
    const ids = selectedBatch.map((batch) => batch._id);
    onDeleteBatch(ids);
  };

  const onDeleteBatch = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/batch`, "delete", { ids }).then((response) => {
      if (response.success) {
        setTimeout(() => {
          setIsBulkDelete(false);
          setDeletedToken(new Date().getTime().toString());
          setSelected([]);
          setIsModalOpen(false);
        }, 2000);
      }
    });
  };

  const handleEdit = (id) => {
    navigation(`/dashboard/addBatch?id=${id}`);
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  return (
    <>
      <Helmet>
        <title> Batch | GurukulGuru.com </title>
      </Helmet>
      <Ribbon message={"Please add institute first to add batch"} />

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("batch")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddBatch}
            disabled={!currentUser?.data?.instituteId}
          >
            {t("newBatch")}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            value={searchValue}
            onChange={handleSearchChange}
            handleDelete={() => {
              setIsModalOpen(true);
            }}
            isBulkDelete={isBulkDelete}
            placeholder={t("searchBatch")}
          >
            {/*
              Other filter would be here
            */}
          </ListToolbar>

          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={batchList.length}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {batchList.map((row) => {
                  const {
                    name,
                    batchNo,
                    startDate,
                    endDate,
                    defaultFee,
                    feeType,
                    batchTeacher,
                  } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={batchNo}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            handleCheckBox(event, name, selected, setSelected)
                          }
                        />
                      </TableCell>
                      <TableCell align="left">{validateData(name)}</TableCell>
                      <TableCell align="left">
                        {validateData(batchNo)}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(batchTeacher?.name)}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(fDate(startDate))}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(fDate(endDate))}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(feeType)}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(defaultFee)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ width: 70 }}>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => handleEdit(row?._id)}
                            sx={{ padding: 0.6 }}
                          >
                            <Iconify icon={"eva:edit-fill"} />
                          </IconButton>
                          {!isLoading(row?._id) ? (
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => {
                                setIsModalOpen(true);
                                setSelected([row?.name]);
                              }}
                              sx={{ padding: 0.6 }}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ color: theme.palette.error.dark }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton size="large" color="inherit">
                              <Iconify icon="eos-icons:bubble-loading" />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No result found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <LoaderWrapper loading={loading} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPage}
            component="div"
            count={batch.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
        <ConfirmationBox
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          loading={isBulkDelete}
          open={isModalOpen}
        />
      </Container>
    </>
  );
}
