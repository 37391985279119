import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AWS from "aws-sdk";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ApiCaller, formatDate, moneyFormat } from "utils";
import { AWS_FOLDERS } from "utils/constants";
import useResponsive from "hooks/useResponsive";
import { useToaster } from "hooks";

const {
  REACT_APP_S3_BUCKET,
  REACT_APP_REGION,
  REACT_APP_ACCESS_KEY,
  REACT_APP_SECRET_ACCESS_KEY,
} = process.env;

const CustomTableRow = ({ label, value }) => (
  <TableRow>
    <TableCell>
      <Typography>{label}</Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ fontWeight: "bold" }}>{moneyFormat(value)}</Typography>
    </TableCell>
  </TableRow>
);

const FeeReceipt = () => {
  const userData = useSelector((state) => state?.loginData);
  const [searchParams] = useSearchParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const componentRef = useRef();
  const triggerToaster = useToaster();

  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    setTimeout(() => {
      generatePdfAndUpload();
    }, 1000);
  }, []);

  const feesDetails = JSON.parse(searchParams.get("fee"));
  const studentDetails = JSON.parse(searchParams.get("student"));
  const institute = JSON.parse(searchParams.get("institute"));

  const totalPayableData = feesDetails?.totalDueBreakup;

  const allDueKeys = totalPayableData.map((item) => item?.key).join(" + ");
  const sumOfDues = totalPayableData.reduce(
    (total, item) => total + +item?.value,
    0
  );

  const generatePdfAndUpload = () => {
    const input = componentRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true); // Enable compression
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(input, {
      useCORS: true,
      scale: 2, // Increase the scale to improve quality
      logging: true,
      width: input.clientWidth,
      height: input.clientHeight,
      imageTimeout: 15000,
      allowTaint: true,
    }).then((canvas) => {
      const imgWidth = pdfWidth;
      const pageHeight = pdfHeight;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Use JPEG format with 70% quality

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const pdfBlob = pdf.output("blob");

      AWS.config.update({
        accessKeyId: REACT_APP_ACCESS_KEY,
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
        region: REACT_APP_REGION,
      });

      const s3 = new AWS.S3();
      const params = {
        Bucket: REACT_APP_S3_BUCKET,
        Key: `${userData?.data?.instituteId}/${
          AWS_FOLDERS.INVOICES
        }/receipt-${Date.now()}.pdf`,
        Body: pdfBlob,
        ContentType: "application/pdf",
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading to S3", err);
          setUploadStatus("fail");
        } else {
          ApiCaller(`/fees/${feesDetails?._id}`, "put", {
            receipt: data?.Location,
          }).then((response) => {
            if (response?.success) {
              triggerToaster("Fee receipt generated successfully", "success");
              setUploadStatus("success");
            } else {
              triggerToaster(
                response.data || "Something went wrong, Please retry.",
                "error"
              );
              setUploadStatus("fail");
            }
          });
        }
      });
    });
  };

  return (
    <Box p={3}>
      <Box
        ref={componentRef}
        sx={{ padding: 3, border: "1px solid #ccc", backgroundColor: "#fff" }}
      >
        {/* Header Section */}
        <Box
          className="print-container"
          sx={{ display: "flex", flexWrap: "nowrap", marginBottom: 2 }}
        >
          <Box className="print-item" sx={{ flex: 1 }}>
            <img
              src={institute?.logo}
              alt={"Institute logo"}
              height={60}
              width={60}
              onLoad={() => setImageLoaded(true)}
              style={{ marginBottom: 2 }}
            />
          </Box>
          <Box className="print-item" sx={{ flex: 1, textAlign: "center" }}>
            <Typography variant="h4" marginTop={0}>
              FEE RECEIPT
            </Typography>
          </Box>
          <Box className="print-item" sx={{ flex: 1, textAlign: "right" }}>
            <Typography variant="body1">
              Date:<b> {formatDate(new Date())}</b>
            </Typography>
          </Box>
        </Box>

        {/* Details Section */}
        <Box
          className="print-container"
          sx={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Box className="print-item" sx={{ flex: 1 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              {institute?.name ?? ""}
            </Typography>
            <Typography variant="body1">{institute?.address ?? ""}</Typography>
            <Typography variant="body1">
              {`${institute?.city}, ${institute?.state}` ?? ""}
            </Typography>
            <Typography variant="body1">
              {`${institute?.country}, ${institute?.pinCode}` ?? ""}
            </Typography>
          </Box>
          <Box className="print-item" sx={{ flex: 1 }}>
            <Typography variant="body1">
              <b>Receipt No</b>
            </Typography>
            <Typography variant="body1">
              {feesDetails?.receiptNumber
                ? feesDetails?.receiptNumber
                : feesDetails?._id}
            </Typography>
          </Box>
          <Box
            className="print-item"
            ml={2}
            sx={{ flex: 1, textAlign: mdUp ? "left" : "left" }}
          >
            <Typography variant="h6">Bill To</Typography>
            <Typography>
              Name: <b>{studentDetails?.name}</b>
            </Typography>
            <Typography>
              Father Name: <b>{studentDetails?.fatherName}</b>
            </Typography>
            <Typography>
              Roll No: <b>{studentDetails?.rollNo}</b>
            </Typography>
            <Typography>
              Batch: <b>{studentDetails?.batchId?.name}</b>
            </Typography>
            <Typography>
              Mobile: <b>{studentDetails?.mobile}</b>
            </Typography>
            <Typography>
              Email: <b>{studentDetails?.email}</b>
            </Typography>
          </Box>
        </Box>

        {/* Table Section */}
        <Box sx={{ marginTop: 2 }}></Box>
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Description</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Total</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totalPayableData?.map((dues) => (
                <CustomTableRow
                  key={dues.key}
                  label={dues?.key}
                  value={+dues?.value}
                />
              ))}
              <CustomTableRow
                label={`Total Due (${allDueKeys})`}
                value={+sumOfDues}
              />
              <CustomTableRow
                label={`Discount`}
                value={+feesDetails?.discountAmount}
              />
              <CustomTableRow
                label={`Balance Paid`}
                value={+feesDetails?.paidAmount}
              />
              <CustomTableRow
                label={`Remaining balance`}
                value={+feesDetails?.remainingAmount}
              />
            </TableBody>
          </Table>
        </TableContainer>

        {/* Footer Section */}
        <Box textAlign="right" sx={{ marginTop: 3, marginRight: 10 }}>
          <Typography variant="h6">
            <b>Balance Paid: {moneyFormat(+feesDetails?.paidAmount)}</b>
          </Typography>
          <Typography variant="h6">
            <b>Paid by: {feesDetails?.receivedFrom}</b>
          </Typography>
        </Box>
        <Box>
          <img
            src={institute?.sign}
            alt={"Institute signature"}
            height={50}
            width={100}
            onLoad={() => setImageLoaded(true)}
          />
          <Typography>Institute Signature</Typography>
        </Box>
        <Box sx={{ marginTop: 3, marginBottom: -2, textAlign: "center" }}>
          <Typography variant="body1">
            {`Gurukulguru.com © 2024 - ${new Date().getFullYear()}. All rights reserved`}
          </Typography>
        </Box>
      </Box>

      {/* Print Button */}
      <Box textAlign="center" sx={{ marginTop: 3 }}>
        {uploadStatus === "success" ? (
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={generatePdfAndUpload}
            disabled={!imageLoaded}
          >
            Generate PDF to print
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FeeReceipt;
