import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import StaffConfirmation from "./StaffConfirmation";

import { useMasterData, useToaster } from "hooks";
import { DatePicker, ErrorMsg, Dropdown, FileUpload } from "components";
import { addStaffSchema } from "./testSchema";
import { ApiCaller, validateError } from "utils";
import useResponsive from "hooks/useResponsive";
import { AWS_FOLDERS } from "utils/constants";
import { useTranslation } from "react-i18next";


export default function AddStaff() {
  const [loading, setLoading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isDirtyFile, setIsDirtyFile] = useState(false);
  const [staffData, setStaffData] = useState({});
  const userData = useSelector((state) => state?.loginData);
  const [searchParams] = useSearchParams();
  const [isStaffConfirmation,setIsStaffConfirmation] = useState(false);
  const triggerMasterData = useMasterData();

  const staffId = searchParams.get("staffId");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addStaffSchema),
  });

  useEffect(() => {
    if (staffId) {
      ApiCaller(`/staffs?key=_id&value=${staffId}`, "get").then((response) => {
        if (response.success && response?.data?.length) {
          const staffDetails = response?.data?.[0];
          setStaffData(staffDetails);
          setValue("name", staffDetails?.name);
          setValue("mobile", staffDetails?.mobile);
          setValue("email", staffDetails?.email);
          setValue("adharNo", staffDetails?.adharNo);
          setValue("qualification", staffDetails?.qualification);
          setValue("joiningDate", new Date(staffDetails?.joiningDate));
          setValue("gender", staffDetails?.gender);
          setValue("role", staffDetails?.role);
          setValue("salaryType", staffDetails?.salaryType);
          setValue("salaryAmount", staffDetails?.salaryAmount);
          setValue("address", staffDetails?.address);
          setPhotoUrl(staffDetails?.profileUrl);
        }
      });
    }
  }, []);

  const handleAddUpdateStaff = () => {
    setLoading(true);

    const body = {
      ...getValues(),
      instituteId: userData?.data?.instituteId,
      profileUrl: photoUrl,
    };

    if (staffId) {
      // Update Batch API call
      ApiCaller(`/staff/${staffId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster("Staff is updated successfully", "success");
          triggerMasterData();
          navigate("/dashboard/staff");
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    } else {
      // Add Batch API call
      ApiCaller("/staff", "post", body).then((response) => {
        if (response?.success) {
          triggerToaster("Staff is added successfully", "success");
          triggerMasterData();
          navigate("/dashboard/staff");
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setLoading(false);
      });
    }
  };
  const { t } = useTranslation();
  const handleConfirmation = () => {
    setIsStaffConfirmation(true);
  };

  return (
    <Stack
      spacing={4}
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleConfirmation)}
        p={4}
        pt={0}
        width={mdUp ? "70%" : "100%"}
        sx={{
          backgroundColor: theme.palette.common.white,
          // boxShadow: `1px 2px 9px ${theme.palette.grey[500]}`,
          // borderRadius: 3,
        }}
      >
        <Typography variant="h3" mt={2}>
          {`${staffId ? "Update staff details" : t("addStaff")}`}
        </Typography>
        <Stack pb={4}>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={4}>
              <Controller
                control={control}
                name="name"
                defaultValue={staffData?.name || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("name")} *`}
                    onChange={onChange}
                    value={value}
                    // error={errors?.name?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.name?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="mobile"
                defaultValue={staffData?.name || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("mobile")} *`}
                    onChange={onChange}
                    value={value}
                    type="number"
                    // error={errors?.mobile?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.mobile?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="email"
                defaultValue={staffData?.name || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("email")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.email?.message} />
            </Grid>
          </Grid>
          {/* Here */}
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={4}>
              <Controller
                control={control}
                name="adharNo"
                defaultValue={staffData?.defaultFee || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("adharNumber")}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.adharNo?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="qualification"
                defaultValue={staffData?.defaultFee || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("qualification")}
                    size="small"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="joiningDate"
                defaultValue={new Date()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={`${t("joiningDate")} *`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMsg msg={errors?.joiningDate?.message} />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mt={mdUp ? 0.5 : 0}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // border: (theme) => `1px solid ${theme.palette.grey[1000]}`,
                  padding: "0px 5px",
                  borderRadius: 1,
                  marginBottom: 3,
                }}
              >
                <FormLabel sx={{ alignSelf: "center", marginRight: 2 }}>
                 {t("gender")}
                </FormLabel>
                <Controller
                  control={control}
                  name="gender"
                  defaultValue={staffData?.gender || "Male"}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={value}
                      size="small"
                      onChange={onChange}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value={"Male"}
                        control={<Radio />}
                        label={t("male")}
                      />
                      <FormControlLabel
                        value={"Female"}
                        control={<Radio />}
                        label={t("female")}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="role"
                defaultValue={staffData?.feeType || "Teacher"}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={[t("admin"), t("teacher"), t("principal"), t("other")]}
                    label={`${t("role")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.role?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={mdUp ? 0 : 1}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="salaryType"
                defaultValue={staffData?.feeType || "Monthly"}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={[
                      // "Daily",
                      // "Weekly",
                      t("monthly"),
                      t("courseWise"),
                      // "Half Yearly",
                      t("oneTime"),
                    ]}
                    label={t("salaryType")}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            {/* salaryAmount */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="salaryAmount"
                defaultValue={staffData?.defaultFee || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("salaryAmount")}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="address"
                defaultValue={staffData?.address || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("address")}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    multiline={true}
                    rows={2}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: -10 }}>
                <FileUpload
                  title={t("attachPhoto")}
                  uploadedUrl={photoUrl}
                  passUploadedUrl={(url) => {
                    setIsDirtyFile(true);
                    setPhotoUrl(url);
                  }}
                  sx={{ paddingTop: 11, paddingBottom: 11 }}
                  folder={AWS_FOLDERS?.STAFFS}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty && !isDirtyFile}
          loading={loading}
        >
          {`${staffId ? t("updateStaff") : t("addStaff")}`}
        </LoadingButton>
        <StaffConfirmation
          open={isStaffConfirmation}
          onClose={() => setIsStaffConfirmation(false)}
          loading={loading}
          onSubmit={handleAddUpdateStaff}
          staffsDetails={getValues()}
        />
      </Box>
      {mdUp && (
        <Box>
          <img
            src="/assets/images/study.png"
            alt="institite"
            width={"100%"}
            style={{ marginTop: 50 }}
          />
        </Box>
      )}
    </Stack>
  );
}
